import { LucideIcon } from "lucide-react";

interface NavigationButtonProps {
  direction: "previous" | "next";
  onClick: () => void;
  className?: string;
  icon: LucideIcon;
}

export default function NavigationButton({
  direction,
  onClick,
  className = "",
  icon: Icon,
}: NavigationButtonProps) {
  return (
    <button
      onClick={onClick}
      className={`absolute top-1/2 -translate-y-1/2 bg-white/90 p-4 md:p-5 
        rounded-full shadow-lg hover:bg-white transition-colors 
        min-w-[48px] min-h-[48px] md:min-w-[56px] md:min-h-[56px] 
        touch-manipulation focus:outline-none focus:ring-2 
        focus:ring-manomay-400 focus:ring-offset-2 ${className}`}
      aria-label={`${direction} award`}
    >
      <Icon className="h-6 w-6 md:h-7 md:w-7 text-gray-600" />
    </button>
  );
}
