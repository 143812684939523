import { useState, useCallback } from "react";
import { useAutoAdvance } from "../../hooks/useAutoAdvance";
import CarouselControls from "./CarouselControls";
import AwardSlide from "./AwardSlide";
import { useSwipe } from "../../hooks/useSwipe";
import type { Award } from "../../types/awards";

interface AwardsCarouselProps {
  awards: Award[];
}

export default function AwardsCarousel({ awards }: AwardsCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = useCallback(() => {
    setCurrentIndex((prev) => (prev === awards.length - 1 ? 0 : prev + 1));
  }, [awards.length]);

  const handlePrevious = useCallback(() => {
    setCurrentIndex((prev) => (prev === 0 ? awards.length - 1 : prev - 1));
  }, [awards.length]);

  useAutoAdvance(handleNext, 5000);

  const { touchHandlers } = useSwipe({
    onSwipeLeft: handleNext,
    onSwipeRight: handlePrevious,
  });

  return (
    <section className="py-16 md:py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-10 md:mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-900">
            Awards & Recognition
          </h2>
          <p className="text-lg md:text-xl text-gray-600 mt-2">
            Celebrating excellence in homeopathic healthcare
          </p>
        </div>

        <div className="relative">
          <div className="overflow-hidden rounded-xl" {...touchHandlers}>
            <div
              className="flex transition-transform duration-500 ease-out"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {awards.map((award) => (
                <AwardSlide key={award.id} award={award} />
              ))}
            </div>
          </div>

          <CarouselControls
            onPrevious={handlePrevious}
            onNext={handleNext}
            currentIndex={currentIndex}
            totalSlides={awards.length}
            onSlideSelect={setCurrentIndex}
          />
        </div>
      </div>
    </section>
  );
}
