interface CarouselDotsProps {
  currentIndex: number;
  totalSlides: number;
  onSelect: (index: number) => void;
}

export default function CarouselDots({
  currentIndex,
  totalSlides,
  onSelect,
}: CarouselDotsProps) {
  return (
    <div className="flex justify-center mt-6 md:mt-8 gap-4 md:gap-5">
      {[...Array(totalSlides)].map((_, index) => (
        <button
          key={index}
          onClick={() => onSelect(index)}
          className={`w-4 h-4 md:w-5 md:h-5 rounded-full transition-colors 
              touch-manipulation focus:outline-none focus:ring-2 
              focus:ring-manomay-400 focus:ring-offset-2 
              ${
                index === currentIndex
                  ? "bg-manomay"
                  : "bg-gray-300 hover:bg-gray-400"
              }`}
          style={{
            minWidth: "1rem",
            minHeight: "1rem",
          }}
          aria-label={`Go to award ${index + 1}`}
          aria-current={index === currentIndex ? "true" : "false"}
        />
      ))}
    </div>
  );
}
