import { ArrowRight } from "lucide-react";

export default function Hero() {
  return (
    <div id="home" className="pt-16 bg-gradient-to-br from-manomay-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 leading-tight">
              Healing Emotional Wellness through
              <span className="text-manomay-400"> Homeopathy</span>
            </h1>
            <p className="mt-4 text-lg text-gray-600">
              Experience the power of holistic healing with our expert
              homeopathic treatments. We focus on treating the root cause, not
              just the symptoms.
            </p>
            <div className="mt-8 flex flex-col sm:flex-row gap-4">
              <button
                onClick={() => {
                  window.open(
                    "https://api.whatsapp.com/send/?phone=918169782983&text&type=phone_number&app_absent=0",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
                className="bg-manomay text-white px-6 py-3 rounded-md hover:bg-manomay-600 transition-colors flex items-center justify-center"
              >
                Book Consultation
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              <a href="#about" rel="noopener noreferrer">
                <button className="border-2 border-manomay text-manomay px-6 py-3 rounded-md hover:bg-manomay-50 transition-colors">
                  Learn More
                </button>
              </a>
            </div>
          </div>
          <div className="relative">
            <picture>
              {/* Retina Display - High-DPI Screens */}
              <source
                srcSet="https://storage.googleapis.com/manomay-homeopathy/miscellaneous/manomay-wallpaper.webp"
                media="(min-width: 1440px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 1440px) and (min-resolution: 192dpi)"
                type="image/webp"
              />

              {/* Standard Desktop and Tablet */}
              <source
                srcSet="https://storage.googleapis.com/manomay-homeopathy/miscellaneous/manomay-wallpaper-1024x683.webp"
                media="(min-width: 768px)"
                type="image/webp"
              />

              {/* Mobile Screens */}
              <source
                srcSet="https://storage.googleapis.com/manomay-homeopathy/miscellaneous/manomay-wallpaper-640x420.webp"
                media="(max-width: 767px)"
                type="image/webp"
              />

              {/* Fallback Image */}
              <img
                src="https://storage.googleapis.com/manomay-homeopathy/miscellaneous/manomay-wallpaper-1024x683.webp"
                alt="Homeopathy Medicine"
                className="rounded-lg shadow-xl"
                width="1024"
                height="683"
              />
            </picture>
            <div className="absolute -bottom-6 -left-6 bg-white p-4 rounded-lg shadow-lg">
              <div className="flex items-center gap-3">
                <div className="bg-manomay-100 p-3 rounded-full">
                  <span className="text-manomay font-bold text-xl">7+</span>
                </div>
                <div>
                  <p className="font-semibold text-gray-900">Years of</p>
                  <p className="text-gray-600">Experience</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
