import React, { Suspense } from "react";
import { Loader } from "lucide-react";
import About from "../components/About";
import AwardsCarousel from "../components/awards/AwardsCarousel";
import Hero from "../components/Hero";
import TreatmentList from "../components/TreatmentList";
import { awards } from '../data/awards';

export default function HomePage() {
  const GoogleReviews = React.lazy(() => import("../components/GoogleReviews"));
  const GoogleMap = React.lazy(() => import("../components/GoogleMap"));
  const renderLoader = () => (
    <div className="flex justify-center items-center py-8">
      <Loader className="w-8 h-8 animate-spin text-manomay" />
    </div>
  );
  return (
    <>
      <Hero />
      <About />
      <TreatmentList />
      <AwardsCarousel awards={awards} />
      <Suspense fallback={renderLoader()}>
        <GoogleReviews />
        <GoogleMap />
      </Suspense>{" "}
    </>
  );
}
