import { Helmet } from 'react-helmet-async';
import { GOOGLE_MAPS_CONFIG } from '../config/google-maps';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
}

export default function SEO({
  title = 'Manomay Homeopathy and Counselling Center',
  description = 'Professional homeopathic care and natural healing solutions for chronic diseases, mental health, and holistic wellness.',
  image = 'https://storage.googleapis.com/manomay-homeopathy/miscellaneous/manomay-wallpaper.webp',
  url = 'https://www.manomayhomeopathy.com'
}: SEOProps) {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'MedicalBusiness',
    '@id': url,
    "name": 'Manomay Homeopathy and Counselling Center',
    image,
    description,
    url,
    telephone: '+91-8169782983',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Fortuna Business Center, Office No. 515, 5th Floor',
      addressLocality: 'Above Hotel Radha Krishna, Opposite McDonald\'s, Shivar Chowk, Pimple Saudagar, Pune, Maharashtra',
      addressRegion: 'Pune',
      postalCode: '411027',
      addressCountry: 'IN'
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 18.5955388,
      longitude: 73.7878762
    },
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        opens: '17:00',
        closes: '20:00'
      }
    ],
    sameAs: [
      'https://facebook.com/manomay.homeo',
      'https://youtube.com/channel/UC1yNCpQ0hAe9tuKUgGTNUYg',
      'https://instagram.com/manomay.homeo/'
    ],
    priceRange: '$$',
    servesCuisine: 'Homeopathic Medicine',
    areaServed: 'Pune',
    hasMap: `https://www.google.com/maps/place/?q=place_id:${GOOGLE_MAPS_CONFIG.placeId}`,
    founder: {
      '@type': 'Person',
      name: 'Dr. Samiksha Murkute',
      jobTitle: 'Homeopathic Psychiatrist & Founder'
    }
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={image} />

      {/* Open Graph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />

      {/* Schema.org JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
}