import { Leaf, Brain, HandHeart, Droplets, Baby, BrainCircuit, LucideIcon  } from 'lucide-react';

export interface TreatmentHeader {
  id: string;
  icon: LucideIcon;
  title: string;
  subtitle: string;
  description: string;
  learnMore: string;
  coverImage: string;
  readTime: string;
  tags: string[];
}

export const treatmentHeaders: TreatmentHeader[] = [
  {
    id: 'managing-chronic-illness',
    icon: Leaf,
    title: 'Managing Chronic Illness: A Natural Path to Wellness',
    subtitle: 'Chronic Illness',
    description: 'Long-term relief from persistent health conditions through constitutional treatment.',
    learnMore: 'Chronic Illenss',
    coverImage: 'https://storage.googleapis.com/manomay-homeopathy/treatments/managing-chronic-illness.webp',
    readTime: '10 min read',
    tags: ['Chronic Illness', 'Natural Healing', 'Wellness']
  },
  {
    id: 'psychiatric-evaluations',
    icon: Brain,
    title: 'Understanding Psychiatric Evaluations and Diagnoses',
    subtitle: 'Psychiatric Evaluations and Diagnoses',
    description: 'Comprehensive assessments to understand and treat mental health concerns effectively.',
    learnMore: 'Psychiatric Evaluations',
    coverImage: 'https://storage.googleapis.com/manomay-homeopathy/treatments/psychiatric-evaluations.webp',
    readTime: '12 min read',
    tags: ['Psychiatry', 'Mental Health', 'Diagnosis']
  },
  {
    id: 'holistic-counselling',
    icon: HandHeart,
    title: 'Holistic Counselling: Integrating Mind and Body',
    subtitle: 'Counselling',
    description: 'Providing counseling for mental illness using various therapies such as REBT, CBT, and talk therapy.',
    learnMore: 'Counselling',
    coverImage: 'https://storage.googleapis.com/manomay-homeopathy/treatments/holistic-counselling.webp',
    readTime: '9 min read',
    tags: ['Counselling', 'Holistic Health', 'Mental Wellness']
  },
  {
    id: 'somatic-psychic-disorders',
    icon: BrainCircuit,
    title: 'Understanding Somatic-Psychic Disorders',
    subtitle: 'Somatic-Psychic Disorder',
    description: 'Condition are treated holistically by addressing the underlying emotional and physical imbalances.',
    learnMore: 'Somatic-Psychic Disorder',
    coverImage: 'https://storage.googleapis.com/manomay-homeopathy/treatments/somatic-psychic-disorders.webp',
    readTime: '11 min read',
    tags: ['Somatic Disorders', 'Mental Health', 'Holistic Treatment']
  },
  {
    id: 'medication-management',
    icon: Droplets,
    title: 'Natural Approaches to Medication Management',
    subtitle: 'Medication Management',
    description: 'Expert assessment and prescription of psychiatric medications when needed, ensuring safe and effective care.',
    learnMore: 'Medication Management',
    coverImage: 'https://storage.googleapis.com/manomay-homeopathy/treatments/medication-management.webp',
    readTime: '10 min read',
    tags: ['Medication', 'Natural Medicine', 'Healthcare']
  },
  {
    id: 'child-adolescent-care',
    icon: Baby,
    title: 'Children & Adolescent Psychiatric Care: A Gentle Approach',
    subtitle: 'Children & Adolescent Psychiatric Care',
    description: 'Gentle, age-appropriate interventions for young patients facing emotional and behavioral challenges.',
    learnMore: 'Children Adolescent Care',
    coverImage: 'https://storage.googleapis.com/manomay-homeopathy/treatments/child-adolescent-care.webp',
    readTime: '13 min read',
    tags: ['Pediatric Care', 'Mental Health', 'Adolescent Health']
  }
];