export const awards = [
  {
    id: 1,
    title: "Excellence in Homeopathy",
    organization: "International Homeopathy Association",
    year: "2023",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/avishkar-certificate.webp",
  },
  {
    id: 2,
    title: "Best Holistic Care Provider",
    organization: "Natural Health Foundation",
    year: "2022",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/avishkar-inter-university-certificate.webp",
  },
  {
    id: 3,
    title: "Research Excellence Award",
    organization: "Global Homeopathy Research Council",
    year: "2021",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/avishkar-presentation-one.webp",
  },
  {
    id: 4,
    title: "Lifetime Achievement Award",
    organization: "National Homeopathy Board",
    year: "2020",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/avishkar-presentation-two.webp",
  },
  {
    id: 5,
    title: "Excellence in Homeopathy",
    organization: "International Homeopathy Association",
    year: "2023",
    image:
      "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/awards-one.webp",
  },
  // {
  //   id: 6,
  //   title: "Best Holistic Care Provider",
  //   organization: "Natural Health Foundation",
  //   year: "2022",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/baecan-case-competition.webp",
  // },
  // {
  //   id: 7,
  //   title: "Research Excellence Award",
  //   organization: "Global Homeopathy Research Council",
  //   year: "2021",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/baecon-foundation-certificate.webp",
  // },
  // {
  //   id: 8,
  //   title: "Lifetime Achievement Award",
  //   organization: "National Homeopathy Board",
  //   year: "2020",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/baecon-foundation.webp",
  // },
  // {
  //   id: 9,
  //   title: "Excellence in Homeopathy",
  //   organization: "International Homeopathy Association",
  //   year: "2023",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/international-conference.webp",
  // },
  // {
  //   id: 10,
  //   title: "Best Holistic Care Provider",
  //   organization: "Natural Health Foundation",
  //   year: "2022",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/interview.webp",
  // },
  // {
  //   id: 11,
  //   title: "Research Excellence Award",
  //   organization: "Global Homeopathy Research Council",
  //   year: "2021",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/linkedin-post.webp",
  // },
  // {
  //   id: 12,
  //   title: "Lifetime Achievement Award",
  //   organization: "National Homeopathy Board",
  //   year: "2020",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/mld-trust-one.webp",
  // },
  // {
  //   id: 13,
  //   title: "Excellence in Homeopathy",
  //   organization: "International Homeopathy Association",
  //   year: "2023",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/mld-trust-two.webp",
  // },
  // {
  //   id: 14,
  //   title: "Best Holistic Care Provider",
  //   organization: "Natural Health Foundation",
  //   year: "2022",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/presentation-one.webp",
  // },
  // {
  //   id: 15,
  //   title: "Research Excellence Award",
  //   organization: "Global Homeopathy Research Council",
  //   year: "2021",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/presentation-two.webp",
  // },
  // {
  //   id: 16,
  //   title: "Lifetime Achievement Award",
  //   organization: "National Homeopathy Board",
  //   year: "2020",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/presentation-three.webp",
  // },
  // {
  //   id: 17,
  //   title: "Excellence in Homeopathy",
  //   organization: "International Homeopathy Association",
  //   year: "2023",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/presentation-four.webp",
  // },
  // {
  //   id: 18,
  //   title: "Best Holistic Care Provider",
  //   organization: "Natural Health Foundation",
  //   year: "2022",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/presentation-five.webp",
  // },
  // {
  //   id: 19,
  //   title: "Research Excellence Award",
  //   organization: "Global Homeopathy Research Council",
  //   year: "2021",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/presentation-six.webp",
  // },
  // {
  //   id: 20,
  //   title: "Lifetime Achievement Award",
  //   organization: "National Homeopathy Board",
  //   year: "2020",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/samajik-nyay.webp",
  // },
  // {
  //   id: 21,
  //   title: "Best Holistic Care Provider",
  //   organization: "Natural Health Foundation",
  //   year: "2022",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/visit-one.webp",
  // },
  // {
  //   id: 22,
  //   title: "Research Excellence Award",
  //   organization: "Global Homeopathy Research Council",
  //   year: "2021",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/visit-two.webp",
  // },
  // {
  //   id: 23,
  //   title: "Lifetime Achievement Award",
  //   organization: "National Homeopathy Board",
  //   year: "2020",
  //   image:
  //     "https://storage.googleapis.com/manomay-homeopathy/awards-recognitions/visit-three.webp",
  // },
];
