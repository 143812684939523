import { ChevronLeft, ChevronRight } from "lucide-react";
import CarouselDots from "./CarouselDots";
import NavigationButton from "./NavigationButton";

interface CarouselControlsProps {
  onPrevious: () => void;
  onNext: () => void;
  currentIndex: number;
  totalSlides: number;
  onSlideSelect: (index: number) => void;
}

export default function CarouselControls({
  onPrevious,
  onNext,
  currentIndex,
  totalSlides,
  onSlideSelect,
}: CarouselControlsProps) {
  return (
    <>
      <NavigationButton
        direction="previous"
        onClick={onPrevious}
        className="left-4"
        icon={ChevronLeft}
      />

      <NavigationButton
        direction="next"
        onClick={onNext}
        className="right-4"
        icon={ChevronRight}
      />

      <CarouselDots
        currentIndex={currentIndex}
        totalSlides={totalSlides}
        onSelect={onSlideSelect}
      />
    </>
  );
}
