import { Link } from "react-router-dom";
import { treatmentHeaders } from "../data/treatmentHeader";

export default function TreatmentList() {
  const handleClick = (id: string) => {
    // Store the button reference in sessionStorage
    sessionStorage.setItem("lastClickedTreatment", id);
  };

  return (
    <section id="treatments" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900">Our Treatments</h2>
          <p className="mt-4 text-xl text-gray-600">
            Comprehensive homeopathic psychiatric care is designed to address a
            wide range of <br />
            mental health challenges with a personalized and empathetic approach
          </p>
        </div>

        <div className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {treatmentHeaders.map((treatment, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow"
            >
              <div className="bg-manomay-100 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                <treatment.icon className="h-6 w-6 text-manomay" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                {treatment.subtitle}
              </h3>
              <p className="text-gray-600">{treatment.description}</p>
              <Link
                aria-label={`treatment-link-${treatment.subtitle}`}
                id={`treatment-link-${treatment.id}`}
                to={`/treatment/${treatment.id}`}
                onClick={() => handleClick(treatment.id)}
                className="inline-flex items-center text-manomay hover:text-manomay-600 font-medium"
              >
                Learn more about {treatment.learnMore.toLowerCase()}
                <svg
                  className="ml-2 w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
