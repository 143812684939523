import { Award, GraduationCap, Users } from "lucide-react";

export default function About() {
  return (
    <section id="about" className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div className="relative">
            <picture>
              {/* Retina Display - High-DPI Screens */}
              <source
                srcSet="https://storage.googleapis.com/manomay-homeopathy/miscellaneous/manomay-founder.webp"
                media="(min-width: 1440px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 1440px) and (min-resolution: 192dpi)"
                type="image/webp"
              />

              {/* Standard Desktop and Tablet */}
              <source
                srcSet="https://storage.googleapis.com/manomay-homeopathy/miscellaneous/manomay-founder-1200x1800.webp"
                media="(min-width: 768px)"
                type="image/webp"
              />

              {/* Mobile Screens */}
              <source
                srcSet="https://storage.googleapis.com/manomay-homeopathy/miscellaneous/manomay-founder-600x900.webp"
                media="(max-width: 767px)"
                type="image/webp"
              />

              {/* Fallback Image */}
              <img
                src="https://storage.googleapis.com/manomay-homeopathy/miscellaneous/manomay-founder-1200x1800.webp"
                alt="Dr. Samiksha Murkute"
                className="rounded-lg shadow-xl"
                width="1200"
                height="1800"
              />
            </picture>
            <div className="absolute -bottom-6 -right-6 bg-white p-4 rounded-lg shadow-lg">
              <div className="flex items-center gap-3">
                <div className="bg-manomay-100 p-3 rounded-full">
                  <Users className="h-6 w-6 text-manomay" />
                </div>
                <div>
                  <p className="font-semibold text-gray-900">500+</p>
                  <p className="text-gray-600">Patients Treated</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2 mb-4">
              <span className="h-1 w-10 bg-manomay rounded"></span>
              <span className="text-manomay font-medium">
                About Our Founder
              </span>
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Dr. Samiksha Murkute
              <span className="block text-xl text-manomay mt-2">
                Homeopathic Psychiatrist & Founder
              </span>
            </h2>
            <p className="text-gray-600 mb-6">
              Dr. Samiksha Murkute is a distinguished Homeopathic physician and
              Psychiatrist, known for her expertise in both holistic and
              psychiatric care. Holding a BHMS and MD (Psychiatry) degree, along
              with specialized training in Psychiatry, she is dedicated to
              treating a wide array of chronic conditions and mental health
              challenges through a patient-centered approach.
              <br />
              <br />
              Her expertise in Psychiatry was nurtured under the guidance of
              leading Psychiatrists such as Dr. Kumar Dhawale, Dr. Manoj Patel,
              and Dr. Sunita Nikumbh. In 2019, she was honored with the
              prestigious Avishkar Award and represented Maharashtra University
              of Science (MUHS) at the State level. Additionally, she has won
              several accolades for her case presentations, including the Beacon
              Case Presentation award. Beyond her clinical practice, Dr. Murkute
              is a passionate educator and public advocate for Mental health
              awareness. Her outreach efforts are particularly focused on
              improving access to healthcare and educating the public about the
              significant role homeopathy can play in treating mental health
              conditions.
              <br />
              <br />
              Her dedication to advancing the fields of homeopathy and mental
              health, coupled with her active involvement in public education,
              positions her as a leading figure in the healthcare community. Her
              work not only supports individual patients but also contributes to
              the broader understanding and acceptance of homeopathy as an
              effective approach to mental wellness.
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              <div className="flex items-start gap-4">
                <div className="bg-manomay-100 p-3 rounded-lg">
                  <GraduationCap className="h-6 w-6 text-manomay" />
                </div>
                <div>
                  <h3 className="font-semibold text-gray-900">Education</h3>
                  <p className="text-gray-600">BHMS, MD in Psychiatry</p>
                </div>
              </div>
              <div className="flex items-start gap-4">
                <div className="bg-manomay-100 p-3 rounded-lg">
                  <Award className="h-6 w-6 text-manomay" />
                </div>
                <div>
                  <h3 className="font-semibold text-gray-900">Recognition</h3>
                  <p className="text-gray-600">Multiple Case Presentations</p>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="font-semibold text-gray-900">Specializations:</h3>
              <ul className="grid grid-cols-2 gap-3">
                {[
                  "Chronic Illness",
                  "Anxiety Disorders",
                  "Depression",
                  "Sleep Disorders",
                  "PTSD",
                  "OCD",
                  "Stress Management",
                  "Anger Management",
                  "Mood Disorders",
                  "Phobia",
                  "Eating Disorders",
                  "Somato-psychic Disorders",
                  "Migraines",
                  "ADHD",
                  "Autism",
                  "Behavioural Issues",
                  "Addictions",
                  "Personality Disorders",
                  "Cognitive Behavioral Therapy (CBT)",
                  "Rational Emotional Behavioral Therapy (REBT)",
                ].map((item, index) => (
                  <li key={index} className="flex items-center">
                    <span className="h-2 w-2 bg-manomay rounded-full mr-2"></span>
                    <span className="text-gray-600">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
