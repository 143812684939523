import type { Award } from "../../types/awards";

interface AwardSlideProps {
  award: Award;
}

export default function AwardSlide({ award }: AwardSlideProps) {
  return (
    <div className="w-full flex-shrink-0 px-4">
      <div className="relative aspect-[4/3] overflow-hidden rounded-xl shadow-xl cursor-pointer group">
        <img
          src={award.image}
          alt={award.title}
          className="absolute inset-0 w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
          loading="lazy"
        />
      </div>
    </div>
  );
}
